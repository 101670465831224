;(function ($) {

    /* Remove ajax loader */
    $(document).on('ajaxStop', function () {
        $.mlsAjax.preloaderHide();
    });

    /* Hover to click on touch devices. Double click to link */
    $('[data-global-doubletap]').doubleTapToGo();

    $(document).on('click', '.hidden-link', function () {
        window.location.href = $(this).data('url');
        return false;
    });

})(jQuery);