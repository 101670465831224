;(function($){

	$.mlsSlider = {

		getCols: function(slide){
			console.log();
			var breakpoints = [768,992,1200];
			var cols = slide ? slide.split(',') : false;

			if($.isArray(cols)){
				cols.shift();
				if(cols.length > 0){
					return $.map(cols, function(value, index){
						return {
							breakpoint: breakpoints[index],
							settings: {
								slidesToShow: parseInt(value)
							}
						}
					});
				}else{
					return false;
				}	  
			}else{
				return false;
			}
		},
		getFirstCol: function(slide){
			var cols = slide ? slide.split(',') : false;
			if(cols){
				return parseInt(slide.split(',')[0]);
			}else{
				return 2;
			}
		},
		// instances
		thumbsSlider: function () {
			var sliders = $('[data-slider="thumbsSlider"]');

			if(sliders.length < 1){
				return false;
			}

			sliders.each(function () {

				var scope       = $(this);
				var slides      = $('[data-slider-slides]', scope).attr('data-slider-slides');
				var breakpoints = $('[data-slider-breakpoints]', scope).attr('data-slider-breakpoints') || null;
				var verticalMode = Boolean(scope.attr('data-slider-vertical')) || false;

				scope.attr('data-slider', false);

				$('[data-slider-slides]', scope)
					.find('[data-slider-slide]')
					.css('float', 'left')
					.end()
					.slick({
						dots:           false,
						arrows:         true,
						adaptiveHeight: true,
						slidesToShow:   $.mlsSlider.getFirstCol(slides),
						autoplay:       false,
						autoplaySpeed:  3000,
						infinite:       false,
						swipeToSlide:   true,
						mobileFirst:    true,
						vertical:       verticalMode,
						verticalSwiping:verticalMode,
						rows:           1,
						prevArrow:      $('[data-slider-arrow-left]', scope).removeClass('hidden'),
						nextArrow:      $('[data-slider-arrow-right]', scope).removeClass('hidden'),
						responsive:     $.mlsSlider.getCols(slides, breakpoints)
					});
			});
		}
	};

	// run instances
	$.mlsSlider.thumbsSlider();
	
})(jQuery);